import React, { useState, useRef } from "react";
import emailjs, { send } from "emailjs-com";

const Krishisat = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const form = useRef(); // Reference to the form

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID, // Access service ID
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID, // Access template ID
        form.current,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY // Access public key
      )
      .then(
        (result) => {
          alert("Form submitted successfully!");
          toggleModal(); // Close the modal
        },
        (error) => {
          alert("Failed to send form. Please try again.");
        }
      );
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen text-white">
      {/* Main Content */}
      <div
        className="w-full max-w-4xl mt-10 overflow-hidden rounded-lg shadow-lg h-96"
        style={{
          borderRadius: "44px",
          backgroundColor: "gray",
        }}
      >
        <div className="w-full h-full rounded-lg shadow-lg">
          {/* Placeholder for broadcast video or image */}
          <div className="w-full h-full">
            <video
              src="https://firebasestorage.googleapis.com/v0/b/xbosonai-caeb0.appspot.com/o/krishisat%20(1).webm?alt=media&token=aab377a8-9c8d-4925-85b4-40f51b14ee3c"
              autoPlay
              loop
              muted
              className="w-full h-full object-cover rounded-[30px]"
            />
          </div>
        </div>
      </div>

      {/* Text Below and Left of the Container */}
      <div className="mt-12 text-center">
        <h2 className="text-xl font-bold lg:text-5xl">Personalized</h2>
        <h2 className="text-xl font-bold lg:text-4xl">
          {/* Apply gradient to the "Agri" text */}
          <span
            style={{
              background:
                "linear-gradient(88.52deg, #1263AF 29.11%, #06CCBC 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Agri
          </span>{" "}
          Broadcast
        </h2>
      </div>

      {/* Join Beta Button */}
      <div className="mt-8">
        <button
          className="px-8 py-4 text-white rounded-full shadow-md hover:bg-blue-600"
          style={{
            background:
              "linear-gradient(88.52deg, #1263AF 29.11%, #06CCBC 100%)",
          }}
          onClick={toggleModal}
        >
          Join our Beta: Get early access by filling out the form!
        </button>
      </div>

      {/* Modal Popup for Form */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-full max-w-lg p-6 bg-gray-800 rounded-lg shadow-lg">
            <h2 className="mb-4 text-2xl font-bold text-center text-white">
              Join the Beta
            </h2>

            <form ref={form} onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block mb-1 text-sm font-semibold text-white">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  className="w-full px-4 py-2 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter your name"
                  required
                />
              </div>

              <div>
                <label className="block mb-1 text-sm font-semibold text-white">
                  Number
                </label>
                <input
                  type="text"
                  name="number"
                  className="w-full px-4 py-2 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter your phone number"
                  required
                />
              </div>

              <div>
                <label className="block mb-1 text-sm font-semibold text-white">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  className="w-full px-4 py-2 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter your email"
                  required
                />
              </div>

              <div>
                <label className="block mb-1 text-sm font-semibold text-white">
                  Message
                </label>
                <textarea
                  name="message"
                  className="w-full px-4 py-2 text-gray-900 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter your message"
                  required
                ></textarea>
              </div>

              <div className="flex justify-between">
                <button
                  type="button"
                  className="px-4 py-2 text-sm font-semibold text-white bg-red-500 rounded-lg hover:bg-red-700"
                  onClick={toggleModal}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  value={send}
                  className="px-4 py-2 text-sm font-semibold text-white rounded-lg"
                  style={{
                    background:
                      "linear-gradient(88.52deg, #1263AF 29.11%, #06CCBC 100%)",
                  }}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Additional Content Below */}
      <div className="w-full max-w-4xl mt-12 space-y-6 text-center">
        <h3 className="text-3xl font-bold text-white">
          Revolutionize{" "}
          <span
            style={{
              background:
                "linear-gradient(88.52deg, #1263AF 29.11%, #06CCBC 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Farming
          </span>{" "}
          with{" "}
          <span
            style={{
              background:
                "linear-gradient(88.52deg, #1263AF 29.11%, #06CCBC 100%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Satellite
          </span>{" "}
          -Powered Insights
        </h3>

        <div className="flex flex-col justify-between space-y-4 lg:space-y-0 lg:space-x-16 lg:flex-row">
          {/* Left Column */}
          <div className="flex flex-col items-start space-y-4">
            <div className="flex items-center">
              <span role="img" aria-label="Globe" className="mr-2">
                🌍
              </span>
              <p>
                Real-Time Monitoring: Get a bird's-eye view of your fields with
                high-resolution satellite imagery.
              </p>
            </div>
            <div className="flex items-center">
              <span role="img" aria-label="Chart" className="mr-2">
                📈
              </span>
              <span>
                Weekly Updates: Receive tailored advisory reports every week,
                designed just for your needs.
              </span>
            </div>
            <div className="flex items-center">
              <span role="img" aria-label="Lightbulb" className="mr-2">
                💡
              </span>
              <span>
                Data-Driven Decisions: Make informed choices with accurate,
                up-to-date information at your fingertips.
              </span>
            </div>
          </div>
          {/* Right Column */}
          <div className="flex flex-col items-start space-y-4">
            <div className="flex items-center">
              <span role="img" aria-label="Plant" className="mr-2">
                🌾
              </span>
              <span>
                Maximize Yields: Optimize your practices to boost productivity
                and increase your harvest.
              </span>
            </div>
            <div className="flex items-center">
              <span role="img" aria-label="Money" className="mr-2">
                💰
              </span>
              <span>
                Reduce Costs: Minimize waste and improve resource efficiency for
                better profit margins.
              </span>
            </div>
          </div>
        </div>

        {/* How It Works Section */}
        <div className="flex flex-col items-center justify-center mt-12 space-y-6">
          <h4 className="text-2xl font-semibold">How It Works:</h4>
          <div className="space-y-4 text-lg">
            <div className="flex items-center">
              <span role="img" aria-label="Notebook" className="mr-2">
                📝
              </span>
              <span>
                Register: Sign up for Krishisat and share your farm details.
              </span>
            </div>
            <div className="flex items-center">
              <span role="img" aria-label="Phone" className="mr-2">
                📲
              </span>
              <span>
                Receive Updates: Weekly advisory reports sent directly to your
                phone.
              </span>
            </div>
            <div className="flex items-center">
              <span role="img" aria-label="Bar chart" className="mr-2">
                📊
              </span>
              <span>
                Analyze Data: Leverage insights for smarter, data-driven
                decisions.
              </span>
            </div>
          </div>
        </div>

        {/* Stats Section */}
        <div className="mt-8 text-center">
  <div className="inline-block border-2 border-[#39baff] rounded-[20px] p-4">
    <p className="text-lg">
      Land Covered: <span className="font-bold">110 Acres</span>
    </p>
    <p className="text-lg">
      Farmers Served: <span className="font-bold">130</span>
    </p>
    <p className="text-lg">
      Advisory Messages: <span className="font-bold">1560</span>
    </p>
  </div>
</div>
{/* Visit Krishisat.in Section */}
<div className="mt-12 space-y-6 text-center">
  <h3 className="text-3xl font-bold">
    Ready to Transform Your Farming?{" "}
    <span
      style={{
        background: "linear-gradient(88.52deg, #1263AF 29.11%, #06CCBC 100%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
      }}
    >
      Visit krishisat.in
    </span>{" "}
    🌱
  </h3>
  
  <p className="max-w-2xl mx-auto text-lg text-gray-300">
    Unlock the full potential of satellite farming technology and start 
    receiving personalized agricultural insights today!
  </p>

  <a 
    href="https://krishisat.in" 
    target="_blank" 
    rel="noopener noreferrer"
    className="inline-block px-8 py-4 text-white transition-opacity rounded-full shadow-md hover:opacity-90"
    style={{
      background: "linear-gradient(88.52deg, #1263AF 29.11%, #06CCBC 100%)",
    }}
  >
    👉 Visit : krishisat.in Now
  </a>
</div>

      </div>
    </div>
  );
};

export default Krishisat;
